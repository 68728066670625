import React from "react";
import cc from "classcat";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "default" | "primary";
}

const Button: React.FunctionComponent<Props> = props => {

  const { variant = "default", className, ...rest } = props;

  const fullClassName = cc( [
    "bg-red-200 px-4 py-1",
    {
      "bg-green-200": variant === "default",
      "bg-green-300": variant === "primary",
      "cursor-not-allowed opacity-50": props.disabled
    },
    className
  ] );

  return (
    <button
      className={fullClassName}
      {...rest}
    >
      {props.children}
    </button>
  );

};

export { Button };
