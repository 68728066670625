import React, { useState } from "react";
import random from "lodash/random";
import shuffle from "lodash/shuffle";
import { ALL_FLAGS } from "./data";
import produce from "immer";
import { GameStep } from "./GameStep";
import { GameEnd } from "./GameEnd";

interface Props {
  questionCount: number;
  optionCount: number;
  onEnded: () => void;
}

export interface GameState {
  questions: Question[];
  currentIndex: number;
}

export interface Question {
  imageUrl: string;
  options: string[];
  correctIndex: number;
  answerIndex: number | null;
  confirmed: boolean;
}

function createGameState( questionCount: number, optionCount: number ): GameState {

  const questions: Question[] = [];

  for( let i = 0; i < questionCount; i++ ) {

    const candidates = shuffle( ALL_FLAGS );
    const selection = candidates.slice( 0, optionCount );
    const index = random( 0, selection.length - 1 );

    questions.push( {
      imageUrl: selection[index].image,
      options: selection.map( s => s.name ),
      correctIndex: index,
      answerIndex: null,
      confirmed: false
    } );

  }

  return {
    questions: questions,
    currentIndex: 0
  };

}

const Game: React.FC<Props> = props => {

  const [gameState, setGameState] = useState<GameState>( () => createGameState( props.questionCount, props.optionCount ) );

  const currentQuestion: Question | null = gameState.currentIndex < gameState.questions.length
    ? gameState.questions[gameState.currentIndex]
    : null;

  function handleSelectAnswer( idx: number ) {
    setGameState( produce( draft => {
      const question = draft.questions[draft.currentIndex];
      question.answerIndex = idx;
    } ) );
  }

  function handleConfirmAnswer() {
    setGameState( produce( draft => {
      const question = draft.questions[draft.currentIndex];
      if( question.answerIndex !== null ) {
        question.confirmed = true;
      }
    } ) );
  }

  function handleNextQuestion() {
    setGameState( produce( draft => {
      const question = draft.questions[draft.currentIndex];
      if( question.confirmed ) {
        draft.currentIndex++;
      }
    } ) );
  }

  return (
    <>

      {currentQuestion && (
        <GameStep
          question={currentQuestion}
          onSelectAnswer={handleSelectAnswer}
          onConfirmAnswer={handleConfirmAnswer}
          onNextQuestion={handleNextQuestion}
        />
      )}

      {!currentQuestion && (
        <GameEnd
          gameState={gameState}
          onBackToStart={props.onEnded}
        />
      )}

    </>
  );

};

export { Game };
