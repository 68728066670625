import React from "react";
import { Question } from "./Game";
import { Button } from "./components/Button";

interface Props {
  question: Question;
  onSelectAnswer: ( idx: number ) => void;
  onConfirmAnswer: () => void;
  onNextQuestion: () => void;
}

const GameStep: React.FC<Props> = props => {

  return (
    <div className={"flex flex-col items-center space-y-8"}>

      <img
        src={props.question.imageUrl}
        alt={"flag"}
        className={"w-96 border border-black"}
      />

      {/* Answer selection */}
      {!props.question.confirmed && (
        <div className={"flex flex-col items-center space-y-8"}>
          <div className={"space-y-2"}>
            {props.question.options.map( ( option, optionIndex ) => {
              return (
                <label className={"block space-x-2"} key={optionIndex}>
                  <input
                    key={optionIndex}
                    type="radio"
                    checked={props.question.answerIndex === optionIndex}
                    onChange={() => props.onSelectAnswer( optionIndex )}
                  />
                  <span>
                      {option}
                    </span>
                </label>
              );
            } )}
          </div>
          <Button onClick={props.onConfirmAnswer}
                  disabled={props.question.answerIndex === null}>
            Antwort prüfen
          </Button>
        </div>
      )}

      {/* Show success message  */}
      {props.question.confirmed && (
        <div className={"flex flex-col items-center space-y-8"}>

          {props.question.correctIndex === props.question.answerIndex ? (
            <p>
              Super! Das ist richtig!
            </p>
          ) : (
            <div className={"text-center space-y-4"}>
              <p>
                Leider falsch. Richtig wäre gewesen:
              </p>
              <p className={"font-bold"}>
                {props.question.options[props.question.correctIndex]}
              </p>
            </div>
          )}

          <Button onClick={props.onNextQuestion}>
            Weiter
          </Button>

        </div>
      )}


    </div>
  );

};

export { GameStep };
