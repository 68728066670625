export interface Flag {
  name: string;
  image: string;
}

export const ALL_FLAGS: Flag[] = [
  {
    name: "LGBT+ Pride Flag",
    image: require( "data-url:./assets/LGBT.svg" )
  },
  {
    name: "Progress-Flag",
    image: require( "data-url:./assets/Progress-Pride-Flag.svg" )
  },
  {
    name: "Alternative LGBT+ Pride Flag",
    image: require( "data-url:./assets/LGBT_alternative.svg" )
  },
  {
    name: "Agender Pride Flag",
    image: require( "data-url:./assets/Agender.svg" )
  },
  {
    name: "Neutrois Pride Flag",
    image: require( "data-url:./assets/Neutrois.svg" )
  },
  {
    name: "Nonbinary Pride Flag",
    image: require( "data-url:./assets/Nonbinary.svg" )
  },
  {
    name: "Genderfluid Pride Flag",
    image: require( "data-url:./assets/Genderfluid.svg" )
  },
  {
    name: "Genderqueer/Nonbinary Pride Flag",
    image: require( "data-url:./assets/Genderqueer.svg" )
  },
  {
    name: "Intersex Pride Flag",
    image: require( "data-url:./assets/Intersex.svg" )
  },
  {
    name: "Pride Flag für queere Weiblichkeit",
    image: require( "data-url:./assets/ceres_flag.svg" )
  },
  {
    name: "Trans Pride Flag",
    image: require( "data-url:./assets/Trans.svg" )
  },
  {
    name: "Demiboy Pride Flag",
    image: require( "data-url:./assets/Demiboy.svg" )
  },
  {
    name: "Demigirl Pride Flag",
    image: require( "data-url:./assets/Demigirl.svg" )
  },
  {
    name: "Demisexuell Pride Flag",
    image: require( "data-url:./assets/Demisexuell.svg" )
  },
  {
    name: "Aromantische Pride Flag",
    image: require( "data-url:./assets/Aromantisch.svg" )
  },
  {
    name: "Asexuelle Pride Flag",
    image: require( "data-url:./assets/Asexuell.svg" )
  },
  {
    name: "Greysexuell Pride Flag",
    image: require( "data-url:./assets/greysexual-1024x614.png" )
  },
  {
    name: "Bisexuell Pride Flag",
    image: require( "data-url:./assets/Bisexuell.svg" )
  },
  {
    name: "Pansexuell Pride Flag",
    image: require( "data-url:./assets/Pansexuell.svg" )
  },
  {
    name: "Polysexuell Pride Flag",
    image: require( "data-url:./assets/Polysexuell.svg" )
  },
  {
    name: "Neue lesbische Pride Flag",
    image: require( "data-url:./assets/Lesbian_Flag_5_neu.svg" )
  },
  {
    name: "Polyamorie Pride Flags",
    image: require( "data-url:./assets/Polyamorie1.svg" )
  }
];