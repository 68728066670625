import React, { useState } from "react";
import { Button } from "./components/Button";
import { Game } from "./Game";

interface Props {

}

type PageType = "start" | "game";

const App: React.FC<Props> = props => {

  const [pageType, setPageType] = useState<PageType>( "start" )

  function startGame() {
    setPageType( "game" );
  }

  return (
    <div className={"container mx-auto mt-10"}>

      <h1 className={"text-center text-6xl"}>
        Fun with flags
      </h1>

      <div className={"w-2/3 mx-auto pt-20"}>

        {pageType === "start" && (
          <div className={"flex flex-col space-y-10 items-center"}>
            <div className={"space-y-5"}>
              <p>
                Die LGBTIAQ*-Gemeinschaft hat sich bestimmte Symbole der Selbstidentifikation zu eigen gemacht, um Verbundenheit,
                Pride, gemeinsame Werte und gegenseitige Loyalität zu zeigen.
              </p>
              <p>
                LGBTIAQ*-Symbole vermitteln Ideen, Konzepte und Identitäten sowohl innerhalb der LGBT-Community, als auch an die
                Mainstream-Kultur. Die beiden LGBTIAQ*-Symbole, die international am bekanntesten sind, sind der Rosa Winkel und die
                Regenbogenfahne.
              </p>
              <p>
                Kennst du die Pride Fahnen? Hier kannst du dein Wissen testen!
              </p>
            </div>
            <Button onClick={startGame}>
              Spiel starten
            </Button>
          </div>
        )}

        {pageType === "game" && (
          <Game
            questionCount={5}
            optionCount={3}
            onEnded={() => setPageType( "start" )}
          />
        )}

      </div>


    </div>
  );

};

export { App };
