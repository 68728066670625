import React from "react";
import { GameState } from "./Game";
import { Button } from "./components/Button";

interface Props {
  gameState: GameState;
  onBackToStart: () => void;
}

const GameEnd: React.FC<Props> = props => {

  const totalCount = props.gameState.questions.length;
  const correctCount = props.gameState.questions.filter( q => q.correctIndex === q.answerIndex ).length;

  return (
    <div className={"text-center space-y-8"}>
      <h2 className={"text-3xl"}>
        Das Spiel ist zu Ende!
      </h2>
      <p className={"text-xl"}>
        Du hast {correctCount} von {totalCount} Fragen korrekt beantwortet!
      </p>
      <Button onClick={props.onBackToStart}>
        Zurück zum Start
      </Button>
    </div>
  );

};

export { GameEnd };
